import { boolean, nullish, number, object } from 'valibot'

/**
 *  The schema for the body of a Solr response.
 */
export const baseBodySchema = object({
  numFound: number(),
  start: number(),
  numberFoundExact: nullish(boolean()),
})
