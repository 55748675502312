import { array, type GenericSchema, type InferOutput, intersect, lazy, nullish, number, object, picklist, string } from 'valibot'
import { baseBodySchema } from './body'
import { type BaseComment, baseCommentSchema } from './comment'
import { type BaseLaw, baseLawSchema } from './law'

export const nodeTypeSchema = picklist([
  'law',
  'chapter',
  'section',
  'subsection',
  'del',
  'paragraph',
  'note',
])

export type NodeType = InferOutput<typeof nodeTypeSchema>

export type BaseBody = InferOutput<typeof baseBodySchema>

// Helpers for fetching comments by lawId
type CommentDocs = BaseBody & {
  docs: Doc[]
}

export const commentDocsSchema = intersect([
  baseBodySchema,
  object({
    // eslint-disable-next-line ts/no-use-before-define
    docs: array(lazy(() => docSchema)),
  }),
])

/* Due to a TypeScript limitation, the input and output types of recursive schemas cannot be inferred automatically.
Therefore, you must explicitly specify these types using GenericSchema.
*/
type Doc = BaseLaw & BaseComment & {
  id: string
  nodeType?: NodeType | null
  timestamp: string
  title?: string | null
  shortTitle?: string | null
  bodytext?: string | null
  _nest_parent_?: string[] | null
  path_location?: string[] | null
  ezMainNodeId?: number | null
  children?: Doc[] | null
  commentDocs?: CommentDocs | null
}

export const docSchema: GenericSchema<Doc> = intersect([
  baseLawSchema,
  baseCommentSchema,
  object({
    nodeType: nullish(nodeTypeSchema),
    timestamp: string(),
    bodytext: nullish(string()),
    _nest_parent_: nullish(array(string())),
    path_location: nullish(array(string())),
    ezMainNodeId: nullish(number()),
    children: nullish(array(lazy(() => docSchema))),
    commentDocs: nullish(commentDocsSchema),
  }),
])

export type SolrDoc = InferOutput<typeof docSchema>
